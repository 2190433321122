import React from 'react';
import './App.css';
import { boundMethod } from "autobind-decorator";
import { Button, TextField } from '@mui/material';

type GetItemProps = {
  token: string;
  apiEndpoint: string;
};

type GetItemState = {
  id: string;
  name: string;
};

class GetItem extends React.Component<GetItemProps, GetItemState>
{
  constructor(props: GetItemProps)
  {
    super(props);
    this.state = {
      id: "",
      name: ""
    };
  }

  componentDidMount()
  {
  }

  @boundMethod
  private onIdChange(e: React.ChangeEvent<HTMLInputElement>): void
  {
    this.setState({id: e.currentTarget.value});
  }

  @boundMethod
  private onClick(): void
  {
    fetch(this.props.apiEndpoint + "/items/" + this.state.id, {
      method: "GET",
      headers: {"Authorization": "Bearer " + this.props.token}
    })
    .then((response) => {
      if (response.status === 404)
      {
        return Promise.resolve({name: "(Not Found)"});
      }
      else if (response.status >= 300)
      {
        return Promise.resolve({name: `(Unexpected response: ${response})`});
      }
      else
      {
        return response.json();
      }
    })
    .then((data) => {
      this.setState({name: data["name"]});
    });
  }

  render()
  {
    return (
      <div className="GetItem">
        <TextField
          id="input-id"
          onChange={this.onIdChange}
          label="ID"
          helperText="Enter ID here."
          variant="outlined" />

        <Button
          id="run-get"
          onClick={this.onClick}
          variant="contained">
            Get
        </Button>

        <TextField
          id="text-name"
          label="Name"
          InputProps={{
            readOnly: true,
          }}
          variant="filled"
          value={this.state.name} />
      </div>
    ); 
  }
}

export default GetItem;