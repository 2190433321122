import React from 'react';
import './App.css';
import GetItem from './GetItem';
import { Typography } from '@mui/material';

/*
// This set is configuration for local development.
const apiEndpoint = "http://localhost:5000";
const loginEndpoint = "";
const loginClientId = "";
*/

// This set is configuration for 'dev' stage of backend.
const apiEndpoint = "https://4dj0zo7sbi.execute-api.us-west-2.amazonaws.com/Prod";
const loginEndpoint = "https://panopto-locapp1-users-dev.auth.us-west-2.amazoncognito.com/login"
const loginClientId = "7hij7md8iaac0n8kei47jun40n";

/*
// TODO: This set is configuration for 'prod' stage of backend.
const apiEndpoint = "https://.execute-api.us-west-2.amazonaws.com/Prod";
const loginEndpoint = "https://panopto-locapp1-users-prod.auth.us-west-2.amazoncognito.com/login"
const loginClientId = "";
*/

type AppProps = {
};

type AppState = {
  token: string | undefined;
};

class App extends React.Component<AppProps, AppState>
{
  // componentDidMount is called second time before setState finally reflects
  // that to the state variables. We need second flag to avoid infinite loop.
  private tokenRetrieved = false;

  constructor(props: AppProps)
  {
    super(props);

    const isLocal = (loginEndpoint.length === 0);
    this.state = {
      token: isLocal ? "dummy_token" : undefined
    };
  }

  componentDidMount()
  {
    const currentUri = window.location.href;
    const rootUri = window.location.protocol + "//" + window.location.host;
    const redirectUri = rootUri + "/redirect";

    if (currentUri.startsWith(redirectUri)) {
      if (currentUri.match(/id_token=([^&]+)/)) {
        this.setState({
          token: currentUri.match(/id_token=([^&]+)/)![1]
        });
        this.tokenRetrieved = true;
      }

      // Replace browser address as root
      const obj = {Title: "", Url: rootUri};
      window.history.replaceState(obj, obj.Title, obj.Url);
    }
    else if (!this.state.token && !this.tokenRetrieved) {
      // Redirect to sign-in page.
      const loginUri = `${loginEndpoint}?response_type=token&client_id=${loginClientId}&scope=openid&redirect_uri=${encodeURIComponent(redirectUri)}`
      window.location.href = loginUri;
    }
  }

  render()
  {
    return (
      <div className="App">
        { (!this.state.token) &&
          <Typography variant="h6">Not authenticated.</Typography>
        }
        { (this.state.token) &&
          <GetItem
            token={this.state.token}
            apiEndpoint={apiEndpoint}
            />
        }
      </div>
    ); 
  }
}

export default App;